<template>
  <PageHeader title="Product List">
    <RouterLink to="/add-product" class="page-header-btn btn btn-dark me-2">ADD</RouterLink>
    <button :disabled="isButtonDisabled" @click="massDelete" type="button" id="delete-product-btn"
            class="page-header-btn btn btn-danger">
      MASS DELETE
    </button>
  </PageHeader>

  <div class="row g-3">
    <div v-for="product in products" :key="product.id" class="col-12 col-sm-6 col-md-4 col-lg-3">
      <div class="card h-100">
        <div class="card-body flex-shrink-0 flex-grow-0 flex-basis-auto">
          <div class="card-title d-flex justify-content-between align-items-center">
            <h5 class="mb-0">{{ product.name }}</h5>
            <input @click="selectProduct(product.sku)" type="checkbox"
                   value="{{ product.sku }}" class="delete-checkbox form-check-input ms-1">
          </div>
          <p class="text-secondary fw-medium mb-0">{{ product.sku }}</p>
        </div>
        <ul class="list-group list-group-flush pb-3">
          <li v-if="product.price" class="list-group-item">
            <span class="text-primary fw-medium">{{ product.price }}$</span>
          </li>
          <li v-if="product.size" class="list-group-item">
            Size: <span class="fw-medium">{{ product.size }}MB</span>
          </li>
          <li v-if="product.weight" class="list-group-item">
            Weight: <span class="fw-medium">{{ product.weight }}KG</span>
          </li>
          <li v-if="product.height" class="list-group-item">
            Height: <span class="fw-medium">{{ product.height }}</span>
          </li>
          <li v-if="product.width" class="list-group-item">
            Width: <span class="fw-medium">{{ product.width }}</span>
          </li>
          <li v-if="product.length" class="list-group-item">
            Length: <span class="fw-medium">{{ product.length }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import PageHeader from '@/components/PageHeader.vue';
  import axios from 'axios';

  export default {
    name: 'ProductListingView',
    components: {
      PageHeader
    },
    data() {
      return {
        products: [],
        selectedSkus: []
      };
    },
    mounted() {
      // Make a GET request to PHP backend API
      axios.get('/api/get-products.php')
          .then(response => {
            console.log(response.data);
            this.products = response.data;
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
    },
    methods: {
      selectProduct(productSku) {
        console.log(this.selectedSkus);
        const index = this.selectedSkus.indexOf(productSku);
        if (index > -1) {
          // SKU is already in the list, so remove it
          this.selectedSkus.splice(index, 1);
        } else {
          // SKU is not in the list, so add it
          this.selectedSkus.push(productSku);
        }
      },
      massDelete() {
        axios.post('/api/delete-products.php', {
          skus: this.selectedSkus
        })
            .then(response => {
              console.log('Products deleted successfully:', response.data);
              // Remove products what have been selected and Clear the selectedSkus array
              this.products = this.products.filter(product => !this.selectedSkus.includes(product.sku));
              this.selectedSkus = [];
            })
            .catch(error => {
              console.error('Error deleting products:', error);
            });
      }
    },
    computed: {
      isButtonDisabled() {
        return this.selectedSkus.length === 0;
      }
    }
  };
</script>

<style lang="scss">
  @import "~bootstrap/scss/card";
  @import "~bootstrap/scss/list-group";

  .page-header-btn {
    min-width: 130px;
  }
</style>