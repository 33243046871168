<template>
  <footer class="container-xl mt-4">
    <div class="py-3 px-2 border-top border-3 border-dark text-center">
      Scandiweb Test assignment
    </div>
  </footer>
</template>

<script>
  export default {
    name: 'MainFooter'
  }
</script>

<style lang="scss" scoped>

</style>
