import { createApp } from 'vue'
import App from './App.vue'
import { createWebHistory, createRouter } from 'vue-router'
import ProductListingView from './views/ProductListingView.vue'
import AddProductView from './views/AddProductView.vue'

const routes = [
    { path: '/', component: ProductListingView },
    { path: '/add-product', component: AddProductView },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

createApp(App)
    .use(router)
    .mount('#app')
