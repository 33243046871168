<template>
  <PageHeader title="Product Add">
    <button @click="handleSubmit" type="button" class="page-header-btn btn btn-dark me-2">Save</button>
    <RouterLink to="/" class="page-header-btn btn btn-danger">Cancel</RouterLink>
  </PageHeader>

  <div class="product-form-wrapper mx-auto">
    <form id="product_form">
      <div v-if="generalError" class="error-message">{{ generalError }}</div>
      <fieldset>
        <div class="mb-3 row">
          <label for="sku" class="col-sm-2 col-form-label">Sku</label>
          <div class="col-sm-10">
            <input v-model="form.sku" name="sku" id="sku" type="text" class="form-control"
                   :class="{ 'is-invalid': errors.sku }">
            <div v-if="errors.sku" class="invalid-feedback">{{ errors.sku }}</div>
          </div>
        </div>
        <div class="mb-3 row">
          <label for="name" class="col-sm-2 col-form-label">Name</label>
          <div class="col-sm-10">
            <input v-model="form.name" name="name" id="name" type="text" class="form-control"
                   :class="{ 'is-invalid': errors.name }">
            <div v-if="errors.name" class="invalid-feedback">{{ errors.name }}</div>
          </div>
        </div>
        <div class="mb-3 row">
          <label for="price" class="col-sm-2 col-form-label">Price ($)</label>
          <div class="col-sm-10">
            <input v-model="form.price" name="price" id="price" type="number" step="0.1" class="form-control"
                   :class="{ 'is-invalid': errors.price }">
            <div v-if="errors.price" class="invalid-feedback">{{ errors.price }}</div>
          </div>
        </div>
        <div class="mb-3 row">
          <label for="productType" class="col-sm-2 col-form-label">Type Switcher</label>
          <div class="col-sm-10">
            <select v-model="form.productType" @change="updateDynamicFields" name="product_type" id="productType"
                    class="form-select" aria-label="Default select example" :class="{ 'is-invalid': errors.productType }">
              <option value="" selected>Type Switcher</option>
              <option value="DVD">DVD-disc</option>
              <option value="Book">Book</option>
              <option value="Furniture">Furniture</option>
            </select>
            <div v-if="errors.productType" class="invalid-feedback">{{ errors.productType }}</div>
          </div>
        </div>
      </fieldset>
      <fieldset v-if="form.productType === 'DVD'">
        <div class="row g-sm-3 align-items-center">
          <div class="col-sm-2">
            <label for="size" class="col-form-label">Size (MB)</label>
          </div>
          <div class="col-sm-auto">
            <input v-model="form.size" name="size" id="size" class="form-control" type="number"
                   :class="{ 'is-invalid': errors.size }">
            <div v-if="errors.size" class="invalid-feedback">{{ errors.size }}</div>
          </div>
          <div class="col-sm-auto">
            <span class="form-text">Please, provide size</span>
          </div>
        </div>
      </fieldset>
      <fieldset v-if="form.productType === 'Book'">
        <div class="row g-sm-3 align-items-center">
          <div class="col-sm-2">
            <label for="weight" class="col-form-label">Weight (Kg)</label>
          </div>
          <div class="col-sm-auto">
            <input v-model="form.weight" name="weight" id="weight" class="form-control" type="number"
                   :class="{ 'is-invalid': errors.weight }">
            <div v-if="errors.weight" class="invalid-feedback">{{ errors.weight }}</div>
          </div>
          <div class="col-sm-auto">
            <span class="form-text">Please, provide weight</span>
          </div>
        </div>
      </fieldset>
      <fieldset v-if="form.productType === 'Furniture'" class="fieldset">
        <div class="mb-3 row">
          <label for="height" class="col-sm-2 col-form-label">Height</label>
          <div class="col-sm-10">
            <input v-model="form.height" name="height" id="height" type="number" class="form-control"
                   :class="{ 'is-invalid': errors.height }">
            <div v-if="errors.height" class="invalid-feedback">{{ errors.height }}</div>
          </div>
        </div>
        <div class="mb-3 row">
          <label for="width" class="col-sm-2 col-form-label">Width</label>
          <div class="col-sm-10">
            <input v-model="form.width" name="width" id="width" type="number" class="form-control"
                   :class="{ 'is-invalid': errors.width }">
            <div v-if="errors.width" class="invalid-feedback">{{ errors.width }}</div>
          </div>
        </div>
        <div class="mb-3 row">
          <label for="length" class="col-sm-2 col-form-label">Length</label>
          <div class="col-sm-10">
            <input v-model="form.length" name="length" id="length" type="number" class="form-control"
                   :class="{ 'is-invalid': errors.length }">
            <div v-if="errors.length" class="invalid-feedback">{{ errors.length }}</div>
            <span class="form-text">Please, provide dimensions</span>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
  import PageHeader from '@/components/PageHeader.vue';
  import axios from 'axios';

  export default {
    name: 'AddProductView',
    components: {
      PageHeader
    },
    data() {
      return {
        form: {
          sku: '',
          name: '',
          price: '',
          productType: '',
          size: '',
          weight: '',
          height: '',
          width: '',
          length: ''
        },
        generalError: '',
        errors: {},
        isValid: true
      }
    },
    methods: {
      updateDynamicFields() {
        this.form.size = '';
        this.form.weight = '';
        this.form.height = '';
        this.form.width = '';
        this.form.length = '';
      },
      setInvalid(field, msg) {
        this.errors[field] = msg;
        this.isValid = false;
      },
      async checkSkuExists(sku) {
        try {
          const response = await axios.post('/api/check-sku.php', { sku });
          return response.data.exists;
        } catch (error) {
          console.error('Error checking SKU:', error);
          return true;
        }
      },
      async validateForm() {
        this.errors = {};
        this.isValid = true;
        const validationSchema = {
          sku: { required: true, type: 'unique-sku' },
          name: { required: true },
          price: { required: true },
          productType: { required: true },
          size: { required: this.form.productType === 'DVD', type: 'integer' },
          weight: { required: this.form.productType === 'Book', type: 'integer' },
          height: { required: this.form.productType === 'Furniture', type: 'integer' },
          width: { required: this.form.productType === 'Furniture', type: 'integer' },
          length: { required: this.form.productType === 'Furniture', type: 'integer' }
        };

        for (const field in validationSchema) {
          const rules = validationSchema[field];
          const value = this.form[field];

          if (rules.required && !value) {
            this.setInvalid(field, 'Please, submit required data');
          } else if (rules.type === 'integer' && !Number.isInteger(Number(value))) {
            this.setInvalid(field, 'Please, submit required data');
          } else if (rules.type === 'unique-sku') {
            const skuExists = await this.checkSkuExists(this.form.sku);

            if (skuExists) {
              this.setInvalid('sku', 'SKU already exists');
            }
          }
        }

        return this.isValid;
      },
      async handleSubmit() {
        if (await this.validateForm()) {
          try {
            const response = await axios.post('/api/add-product.php',  this.form );
            console.log('Successfull form submition:', response.data);
            if (response.data) {
              this.$router.push('/');
            } else {
              this.generalError = 'Sorry something went wrong';
            }
          } catch (error) {
            console.error('Error submiting Form:', error);
          }
        } else {
          console.log('Form did not submited:', this.form);
          console.log('Errors:', this.errors);
        }
      },
    }
  }
</script>

<style lang="scss">
  @import "~bootstrap/scss/forms";

  .page-header-btn {
    min-width: 130px;
  }

  .product-form-wrapper {
    max-width: 750px;
  }
</style>
