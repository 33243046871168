<template>
  <main class="container-xl pt-4">
    <RouterView />
  </main>
  <MainFooter />
</template>

<script>
  import MainFooter from "@/components/MainFooter.vue";

  export default {
    name: 'App',
    components: {
      MainFooter
    }
  }
</script>

<style lang="scss">

</style>
