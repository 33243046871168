<template>
  <div class="row g-3 pb-2 mb-4 border-bottom border-3 border-dark">
    <div class="col">
      <h1 class="page-title text-nowrap mb-0">{{ title }}</h1>
    </div>
    <div class="col-auto d-flex align-items-center flex-wrap">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PageHeader',
    props: {
      title: {
        type: String,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  @include media-breakpoint-down(sm) {
    .page-title {
      font-size: 30px;
    }
  }
</style>
